<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-sm bg-light text-primary my-auto">
      <div class="card-body text-center">
        <div class="my-4"><i class="fas fa-square-sliders fa-5x"></i></div>
        <h1 class="fw-bold">Settings</h1>
        <p class="fw-bold mb-5">
          Use the navigation on the left to manage your personal details, clinic
          information, business hours, integrations and more!
        </p>

        <router-link
          to="/support-requests/create"
          class="btn btn-outline-primary"
          >Need some help?</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "path"],
  components: {},
};
</script>

<style>
</style>
